import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>

    <SEO title="Welcome" keywords={[
      `flame and hog`,
      `hog roast`,
      `lamb roast`,
      `bbq catering`
    ]} />

    <div className="grey-bg">
      <Container>
        <Row className="global-padding">
          <Col md={{ span: 8, offset: 2 }}>
            <h2>
              Welcome to Flame &amp; Hog
            </h2>
            <p>
              Our unswerving commitment to quality is essential for a memorable event. As members of the Institute of Meat we pride ourselves on only ever using the freshest ingredients. As a North West company, we take advantage of our location to support local business and source our ingredients from this great farming area. All our meat is carefully selected by Tim, from the beef for our homemade burgers to sirloins, whole hogs, chicken and lamb.
            </p>
            <p>
              With over forty years of experience in butchery and hospitality, serving thousands of delighted clients, you can be assured of outstanding quality and excellent service. From traditional cooking styles to Mediterranean, Caribbean and South American dishes, we cater for all tastes.
            </p>
            <Link to="/about" className="btn btn-secondary btn-block">
              Read more about us
            </Link>
          </Col>
        </Row>
      </Container>
    </div>

  </Layout>
)

export default IndexPage
